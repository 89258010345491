<template>
    <v-app>
        <!-- <Drawer/> -->
        <Navbar/>
        <!-- margin-top: 48px; margin-bottom: 88px; still needed because 'fluid' prop on container isn't adjusting the content by the correct amount (and we have the 'app' props on the navbar and footer) -->
        <v-content style="background-color:#efefef;" class="print"> <!-- class="py-3 px-3"   cant do this AND margin , it messes up    margin-top: 48px; margin-bottom: 88px; -->
            <!-- pelican cove uses purple lighten-5 -->
            <!-- purple  #9c27b0 -->
            <!-- purple lighten-4 : #e1bee7 -->
            <!-- purple lighten-4.5  : #efd5f1 -->
            <!-- purple lighten-5 : #f3e5f5 -->
            <v-container fluid>
                <router-view></router-view>
            </v-container>
        </v-content>
        <Footer/>
    </v-app>
</template>

<style>
  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>

<script>
// import '@/assets/font/NunitoSans/NunitoSans.css'
// import Drawer from '@/components/Drawer.vue';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
    name: 'App',

    components: {
        // Drawer,
        Navbar,
        Footer,
    },

    created() {
        console.log('app.vue: created, initializing');
        this.$store.dispatch('init');

        // call when tab is selected
        window.onfocus = () => {
            this.$store.dispatch('refresh');
        };
    },
};
</script>
