import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            component: () => import(/* webpackChunkName: "front" */ './views/Front.vue'),
        },
        {
            path: '/about',
            name: 'about',
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
        },
        {
            path: '/about/integration',
            name: 'about-integration',
            component: () => import(/* webpackChunkName: "about-integration" */ './views/AboutIntegration.vue'),
        },
        {
            path: '/about/privacy',
            name: 'about-privacy',
            component: () => import(/* webpackChunkName: "about-privacy" */ './views/AboutPrivacy.vue'),
        },
        {
            path: '/about/terms',
            name: 'about-terms',
            component: () => import(/* webpackChunkName: "about-terms" */ './views/AboutTerms.vue'),
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            component: () => import(/* webpackChunkName: "interaction" */ './views/Interaction.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
            path: '/preferences',
            name: 'preferences',
            component: () => import(/* webpackChunkName: "preferences" */ './views/Preferences.vue'),
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
        },
        {
            path: '/note',
            name: 'note',
            component: () => import(/* webpackChunkName: "note" */ './views/Note.vue'),
        },
        {
            path: '/create-account',
            name: 'create-account',
            component: () => import(/* webpackChunkName: "create-account" */ './views/CreateAccount.vue'),
        },
        {
            path: '/create-account/conflict',
            name: 'create-account-conflict',
            component: () => import(/* webpackChunkName: "create-account-conflict" */ './views/CreateAccountConflict.vue'),
        },
        {
            path: '/create-account/login',
            name: 'create-account-login',
            component: () => import(/* webpackChunkName: "create-account-login" */ './views/CreateAccountLogin.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
